import { createMuiTheme, lighten, withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

import LinearProgress from '@material-ui/core/LinearProgress';

export const theme = createMuiTheme({
  typography: {
    fontFamily: 'Open Sans, sans-serif',
  },
  overrides: {
    MuiPaper: {
      root: {
        position: 'absolute',
        width: '95%',
        top: '50%',
        transform: 'translateY(-60%)',
        left: '0',
      },
    },
    MuiCardContent: {
      root: {
        width: '100%',
        height: '45px',
      },
    },
    MuiCard: {
      root: {
        margin: '-10px 5vh 0',
        border: '1px solid rgba(129, 157, 240, 1)',
        borderRadius: ' 15px',
        gridArea: '3/1/5/1',
        alignSelf: 'end',
        zIndex: 2,
        maxWidth: '1000',
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.16)',
      },
    },
    MuiCardHeader: {
      root: {
        height: '33px',
        gridArea: 'title',
        backgroundColor: '#ffffff',
      },
      title: {
        fontSize: '16px',
        color: '#000000',
      },
    },
  },
});

export const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: 5,
  },
  orderLabel: {
    fontSize: '20px',
    marginLeft: '16px',
    marginTop: '16px',
  },
  orderInfo: {
    marginTop: '0px',
    marginBottom: '-9px',
    [theme.breakpoints.down('xs')]: {
      height: '52px',
      display: 'flex',
      margin: 'auto',
      justifyContent: 'center',
      alignItems: 'start',
      flexDirection: 'column',
    },
  },
  progressBarContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  progressBar: {
    [theme.breakpoints.down('xs')]: {
      width: '126px',
      height: '52px',
      position: 'relative',
      transition: 'none',
      transform: 'none',
      top: 0,
      left: 0,
      margin: '0 !important',
      border: '0 !important',
      textAlign: 'center',
    },
  },
  text: {
    fontSize: '12px',
    color: '#666666',
    marginRight: '16px',
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  status: {
    fontSize: '16px',
    marginRight: '19px',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      fontSize: '12px',
    },
  },
  successColor: {
    color: '#28baa4 !important',
  },
  card: {
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    maxHeight: '35vh',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '150px',
      margin: '16px',
      width: '350px',
    },
  },
  cardContent: {
    overflow: 'auto',
    padding: '0 !important',
    height: 'calc(100% - 75px)',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '150px',
      overflow: 'hidden',
      padding: '4px 5px !important',
      margin: '0 !important',
    },
  },
});
export const BorderLinearProgress = withStyles({
  root: {
    height: 12,
    color: lighten('#28baa4', 0.5),
    backgroundColor: '#ffffff',
    marginBottom: '5px',
  },
  bar: {
    borderRadius: 14,
    color: '#28baa4',
  },
  barColorPrimary: {
    backgroundColor: '#28baa4',
  },
})(LinearProgress);
