import React, { useEffect } from 'react';
import {
  Theme,
  createStyles,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Visit } from '../Home/sagas';
import { Dialog, AppBar, Toolbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import 'simple-lightbox/dist/simpleLightbox.css';

const SimpleLightbox = require('simple-lightbox');

const defaultTheme = createMuiTheme();

const someTheme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paper: {
        overflowY: 'inherit',
      },
    },
    MuiAppBar: {
      root: {
        backgroundImage: 'linear-gradient(#819df0, #24dfe8)',
      },
    },
    MuiGridList: {
      root: {
        flexWrap: 'nowrap',
      },
    },
  },
});

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      gridArea: 'images',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      position: 'relative',
      paddingTop: 'calc(100% / 1.5)',
      [defaultTheme.breakpoints.down('xs')]: {
        paddingTop: 'calc(100% / 2.5)',
      },
    },
    GridListTile: {
      height: '100%',
    },
    gridList: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      height: '100%',
      width: '100%',
      transform: 'translateZ(0)',
    },
    title: {
      // color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    img: {
      borderColor: '#cccccc',
      border: '1px',
      borderStyle: 'solid',
      margin: 'auto',
      display: 'block',
      height: '100%',
      width: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
    },
    fullsizeImg: {
      height: '100%',
      width: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
    },
    fullsizeImgWrap: {
      gridArea: '2/1/3/1',
    },
    appBar: {
      position: 'relative',
      gridArea: '1/1/1/1',
      margin: 0,
    },
    grid: {
      display: 'grid',
      gridTemplateRows: '64px auto',
      gridTemplateColumns: '1fr',
      height: '100%',
      width: '100%',
    },
  }),
);

interface SingleLineGridListProps {
  visit: Visit;
}

export function SingleLineGridList({ visit }: SingleLineGridListProps) {
  const classes = useStyles();

  const isSmallDevice = useMediaQuery(defaultTheme.breakpoints.down('xs'));

  const [open, setOpen] = React.useState(false);
  const [selectedImg, setSelectedImg] = React.useState('');

  function handleClose() {
    setOpen(false);
  }

  useEffect(() => new SimpleLightbox({ elements: '.image-list-gallery' }), []);

  return (
    <MuiThemeProvider theme={someTheme}>
      <div className={classes.root}>
        <GridList
          className={classes.gridList}
          cols={isSmallDevice ? 2.5 : 1.5}
          cellHeight={'auto'}
          style={{ margin: 0 }}>
          {visit.signature ? (
            <GridListTile
              key="signature"
              className={classes.GridListTile}
              rows={1}
              cols={1}
              style={{ margin: '0px 6px 0px 0px', padding: 0 }}>
              <a className="image-list-gallery" href={visit.signature}>
              <img
                data-cy="visit-detail.signature"
                src={visit.signature}
                className={classes.img}
                alt=""
              />
              </a>
            </GridListTile>
          ) : (
            undefined
          )}
          {visit.images.map((picture, index) => (
            <GridListTile
              key={index}
              className={classes.GridListTile}
              rows={1}
              cols={1}
              style={{ margin: '0px 6px 0px 0px', padding: 0 }}>
              <a className="image-list-gallery" href={picture}>
                <img
                src={picture}
                className={classes.img}
                alt=""
                data-cy="visit-detail.pictures"
              />
              </a>
            </GridListTile>
          ))}
        </GridList>
        <Dialog fullScreen={true} open={open} onClose={handleClose}>
          <div className={classes.grid}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  onClick={handleClose}
                  aria-label="Close">
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div className={classes.fullsizeImgWrap}>
              <img className={classes.fullsizeImg} alt="" src={selectedImg} />
            </div>
          </div>
        </Dialog>
      </div>
    </MuiThemeProvider>
  );
}
