import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';

import createSagaMiddleware from 'redux-saga';

import rootReducer from '../reducers';

import { rootSaga } from '../components/Home/sagas';

const sagaMiddleware = createSagaMiddleware();

export default (initialState: object) => {
  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(reduxImmutableStateInvariant(), sagaMiddleware),
    ),
  );
  sagaMiddleware.run(rootSaga);
  return store;
};
