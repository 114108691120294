export const text = {
  es: {
    translation: {
      Title: 'Detalles del pedido',
      AddressTitle: 'DIRECCIÓN',
      ContactTitle: 'CONTACTO',
      OtherValues: 'OTROS VALORES',
      Loading: 'Cargando',
      Error: 'Error al obtener la data ',
      ImageDownloadLink: 'Descargar archivo de imágenes',
      ImageCreateLink: 'Generar archivo de descarga',
      Status: 'Estado del pedido',
      ShowMore: 'Mostrar Más',
      ShowLess: 'Mostrar Menos',
      CHECKOUT_SUCCESS: 'Entrega exitosa',
      CHECKOUT_FAILED: 'Entrega fallida',
      ON_ITS_WAY: 'En camino a dirección de entrega',
      ROUTE_STARTED: 'Pedido salió de bodega',
      CREATED: 'Pedido programado para entrega',
      UPDATE_DELIVERY_TRACK: 'Información del pedido actualizado',
      ORDER: 'Pedido',
      CONCISE_CHECKOUT_SUCCESS: 'Entregado',
      CONCISE_CHECKOUT_FAILED: 'Fallido',
      CONCISE_ON_ITS_WAY: 'En camino',
      CONCISE_ROUTE_STARTED: 'En ruta',
      CONCISE_CREATED: 'Programado',
      CONCISE_ORDER: 'Pedido',
      SEARCH: 'Ingresar código de seguimiento',
      INCORRECT_CODE: 'El código ingresado es incorrecto',
      TRY_AGAIN: 'Por favor inténtelo nuevamente',
      FROM: 'Entre',
      AND: 'y',
      TIME: 'Hora',
      DATE: 'Fecha',
      SERVICE_TIME: 'Tiempo de servicio',
      ITEMS: 'ITEMS',
      ITEM: 'ITEM',
      ITEMS_SUMMARY: 'entregados de',
      SHOW_ITEMS: 'VER ITEMS',
      ITEMS_DISPATCHED: 'ITEMS DESPACHADOS',
      QUANTITY_PLANNED: 'CANTIDAD PROGRAMADA',
      QUANTITY_DELIVERED: 'CANTIDAD ENTREGADA',
      TOTAL: 'TOTAL'
    },
  },
  en: {
    translation: {
      Title: 'Order Details',
      AddressTitle: 'ADDRESS',
      ContactTitle: 'CONTACT',
      OtherValues: 'OTHER VALUES',
      Loading: 'Loading',
      Error: 'Error when obtaining the data  ',
      ImageDownloadLink: 'Download image file',
      ImageCreateLink: 'Generate download file',
      Status: 'Order Status',
      ShowMore: 'Show More',
      ShowLess: 'Show Less',
      CHECKOUT_SUCCESS: 'Successful delivery',
      CHECKOUT_FAILED: 'Failed delivery',
      ON_ITS_WAY: 'On its way to delivery address',
      ROUTE_STARTED: 'Delivery on route',
      CREATED: 'Order scheduled for delivery',
      UPDATE_DELIVERY_TRACK: 'Updated order information',
      ORDER: 'Order',
      CONCISE_CHECKOUT_SUCCESS: 'Delivered',
      CONCISE_CHECKOUT_FAILED: 'Failed',
      CONCISE_ON_ITS_WAY: 'On its way',
      CONCISE_ROUTE_STARTED: 'On route',
      CONCISE_CREATED: 'Scheduled',
      CONCISE_ORDER: 'Order',
      SEARCH: 'Enter tracking code',
      INCORRECT_CODE: 'The given code is incorrect.',
      TRY_AGAIN: 'Please try again.',
      FROM: 'Between',
      AND: 'and',
      TIME: 'Time',
      DATE: 'Date',
      SERVICE_TIME: 'Service time',
      ITEMS: 'ITEMS',
      ITEM: 'ITEM',
      ITEMS_SUMMARY: 'delivered from',
      SHOW_ITEMS: 'SHOW ITEMS',
      ITEMS_DISPATCHED: 'ITEMS DISPATCHED',
      QUANTITY_PLANNED: 'QUANTITY PLANNED',
      QUANTITY_DELIVERED: 'QUANTITY DELIVERED',
      TOTAL: 'TOTAL'
    },
  },
  pt: {
    translation: {
      Title: 'Detalhes do pedido',
      AddressTitle: 'ENDEREÇO',
      ContactTitle: 'CONTATO',
      OtherValues: 'OUTROS VALORES',
      Loading: 'Carregando',
      Error: 'Erro ao obter dados ',
      ImageDownloadLink: 'Baixar arquivo de imagem',
      ImageCreateLink: 'Gerar arquivo de imagem',
      Status: 'Status do pedido',
      ShowMore: 'Mostre Mais',
      ShowLess: 'Mostre Menos',
      CHECKOUT_SUCCESS: 'Entrega bem sucedida',
      CHECKOUT_FAILED: 'Falha na entrega',
      ON_ITS_WAY: 'A caminho do endereço de entrega',
      ROUTE_STARTED: 'Entrega na rota',
      CREATED: 'Pedido agendado para entrega',
      UPDATE_DELIVERY_TRACK: 'Informações atualizadas do pedido',
      ORDER: 'Pedido',
      CONCISE_CHECKOUT_SUCCESS: 'Entregue',
      CONCISE_CHECKOUT_FAILED: 'Falhou',
      CONCISE_ON_ITS_WAY: 'A Caminho',
      CONCISE_ROUTE_STARTED: 'Na Rota',
      CONCISE_CREATED: 'Agendado',
      CONCISE_ORDER: 'Pedido',
      SEARCH: 'Insira o código de rastreamento',
      INCORRECT_CODE: 'O código digitado está incorreto. ',
      TRY_AGAIN: 'Tente novamente',
      FROM: 'Entre',
      AND: 'é',
      TIME: 'Hora',
      DATE: 'Data',
      SERVICE_TIME: 'Tempo de serviço',
      ITEMS: 'ITENS',
      ITEM: 'ITEN',
      ITEMS_SUMMARY: 'entregue de',
      SHOW_ITEMS: 'VER ITENS',
      ITEMS_DISPATCHED: 'ITENS DESPACHADOS',
      QUANTITY_PLANNED: 'QUANTIDADE PROGRAMADA',
      QUANTITY_DELIVERED: 'QUANTIDADE ENTREGADA',
      TOTAL: 'TOTAL'
    },
  },
};
