import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';

import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  withWidth,
} from '@material-ui/core';
import { StylesProvider, createGenerateClassName } from '@material-ui/styles';
import {
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';

import { SimpleCard } from '../contactDetails/contactDetails';
import { SimpleStatusCard } from '../statusDetails/statusDetails';
import { StatusProgressBar } from '../statusProgressBar/statusProgressBar';
import { CompanyImage } from '../companyImage/companyImage';
import logo from '../../assets/images/logo_white.svg';
import styles from './styles';
import Home from '../Home';

import State from './State';
import Props from './Props';
import withRoot from '../../withRoot';

const someTheme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      root: {
        backgroundImage: 'linear-gradient(#819df0, #24dfe8)',
      },
    },
  },
});

const generateClassName = createGenerateClassName({
  productionPrefix: 'prod',
  seed: 'live-tracking',
});

const heights = {
  OPEN: '70vh',
  CLOSED: '45vh',
  FULL: 'calc(100vh - 64px)',
};

export class StateView extends Component<Props, State> {
  togglerIsEnabled = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      isSmallDevice: this.props.width === 'xs',
      containerHeight: heights.OPEN,
    };
  }

  componentDidMount() {
    if (this.state.isSmallDevice) {
      this.setState({ containerHeight: heights.CLOSED });
    }
  }

  shouldToggleStatusView = (value: boolean) => {
    this.togglerIsEnabled = value;
  };

  displayFullDetail = (full: boolean) => {
    if (full) {
      this.setState({ containerHeight: heights.FULL });
    } else {
      this.setState({ containerHeight: heights.CLOSED });
    }
  };

  toggleStatusView = () => {
    if (!this.state.isSmallDevice || !this.togglerIsEnabled) {
      return;
    }
    if (this.state.containerHeight === heights.CLOSED) {
      this.setState({ containerHeight: heights.OPEN });
    } else if (this.state.containerHeight === heights.OPEN) {
      this.setState({ containerHeight: heights.CLOSED });
    }
  };

  render() {
    const { classes, store } = this.props;
    return (
      <StylesProvider generateClassName={generateClassName}>
        <MuiThemeProvider theme={someTheme}>
          <div className={classes.root}>
            <Suspense fallback='loading'>
              <AppBar position='static'>
                <Toolbar style={{ paddingLeft: 0 }}>
                  <CompanyImage visit={store.visit.visit} />
                  <Typography
                    component='div'
                    variant='h6'
                    color='inherit'
                    className={classes.flex}
                  >
                    Powered by
                  </Typography>
                  <img className={classes.logo} alt='logo' src={logo} />
                </Toolbar>
              </AppBar>
              <Grid
                container={true}
                direction='column'
                style={{ height: 'calc(100vh - 64px )' }}
              >
                <Grid item={true} style={{ flex: 1, marginBottom: '0px' }}>
                  {this.props.accountId ? <Home accountId={this.props.accountId} /> : <Home />}
                </Grid>
                {store.visits && store.visits.visits ? (
                  <React.Fragment>
                    <React.Fragment>
                      <Grid
                        container={true}
                        direction={'row'}
                        className={
                          this.state.containerHeight === heights.FULL
                            ? classes.cardsContainerFull
                            : classes.cardsContainerRow
                        }
                        style={{ height: this.state.containerHeight }}
                        onClick={this.toggleStatusView}
                      >
                        <StatusProgressBar visit={store.visit.visit} />
                        <Grid item={true} xs={12} md={6} lg={6}>
                          <SimpleStatusCard
                            shouldToggleStatusView={this.shouldToggleStatusView}
                            visits={store.visits.visits}
                            mobileViewDisplayAll={this.displayFullDetail}
                          />
                        </Grid>
                        {store.visit && store.visit.visit && (
                          <Grid item={true} xs={12} md={6} lg={6}>
                            <SimpleCard visit={store.visit.visit} />
                          </Grid>
                        )}
                      </Grid>
                    </React.Fragment>
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
              </Grid>
            </Suspense>
          </div>
        </MuiThemeProvider>
      </StylesProvider>
    );
  }
}

const mapStateToProps = (state: any) => ({
  store: state,
});

export default withRoot(
  withStyles(styles)(connect(mapStateToProps)(withWidth()(StateView))),
);
