import React from 'react';
import axios, {AxiosRequestConfig} from 'axios';

import { Visit } from '../Home/sagas';
import { useTranslation } from 'react-i18next';

import { Button, Link } from '@material-ui/core';
import Loader from 'react-loader-spinner';

interface DownloadImagesProps {
  visit: Visit;
}

interface DownloadImagesRequest {
  force: boolean;
  id?: string;
  files: DownloadImageFile[];
}

interface DownloadImageFile {
  url: string;
  name: string;
}

interface DownloadImagesResponse {
  url: string;
}

export function DownloadImages({ visit }: DownloadImagesProps) {
  const { t } = useTranslation();

  const imageRequest: DownloadImagesRequest = {
    force: false,
    id: visit.trackingId,
    files: filesNormalizer(visit),
  };

  const options: AxiosRequestConfig = {
    method: 'post',
    url: process.env.REACT_APP_file_compressor,
    data: imageRequest,
  };

  const preventDefault = (event: React.FormEvent<HTMLInputElement>) =>
    event.preventDefault();

  const [isLoading, setIsLoading] = React.useState(false);
  const [downloadUrl, setDownloadUrl] = React.useState('');

  const fetchData = (axiosOptions: AxiosRequestConfig) => {
    setIsLoading(true);
    axios.request(axiosOptions).then(
      response => {
        setDownloadUrl(response.data.url);
        setIsLoading(false);
      },
      error => {
        setDownloadUrl('');
        setIsLoading(false);
      },
    );
  };

  if (isLoading === true) {
    return (
      <Button color="primary" size="small" disabled={true}>
        <Loader type="ThreeDots" color="#819df0" height={10} width={30} />
      </Button>
    );
  } else if (downloadUrl !== '') {
    return (
      <a
        href={downloadUrl}
        target="_blank"
        style={{ margin: '10px', padding: '5px', textDecoration: 'none' }}>
        {t('ImageDownloadLink')}
      </a>
    );
  } else {
    return (
      <Button color="primary" size="small" onClick={() => fetchData(options)}>
        {t('ImageCreateLink')}
      </Button>
    );
  }
}

export const filesNormalizer = (visit: Visit): DownloadImageFile[] => {
  const files = [];

  for (const img of visit.images) {
    files.push(buildFile(img));
  }

  if (visit.signature) {
    files.push(buildFile(visit.signature));
  }

  return files;
};

const buildFile = (url: string): DownloadImageFile => {
  const name = url.substring(url.lastIndexOf('/') + 1);

  return {
    url: url,
    name: name,
  };
};
