import React, { Component, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GoogleMapReact, { Coords } from 'google-map-react';
import { styles, infoWindowStyles } from './styles';
import State from './State';
import Props from './Props';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { SANTIAGO } from './constants';
import { getTrackingID } from '../../algorithms/url.utils';
import { ReactComponent as VisitLocationMark } from '../../assets/images/visit_location_mark.svg';
import { ReactComponent as DriverLocationMark } from '../../assets/images/driver_location_mark.svg';
import { ReactComponent as CheckoutLocationMark } from '../../assets/images/checkout_location_mark.svg';
import { defaultTo, view, lensPath } from 'ramda';
import { gotTrackingID } from './sagas';
import firebase from 'firebase/app';
import { auth } from '../../algorithms/firebase.utils';
import { fitBounds } from '../../algorithms/maps.utils';

export const VisitMark = (props: Coords) => (
  <VisitLocationMark
    height='24px'
    width='24px'
    className='visit_location'
    data-lat={props.lat}
    data-lng={props.lng}
  />
);

export const DriverMark = (props: Coords) => (
  <DriverLocationMark
    height='48px'
    width='48px'
    className='driver_location'
    data-lat={props.lat}
    data-lng={props.lng}
  />
);

const CheckoutInfoWindow = (props: any) => {
  const classes = infoWindowStyles();
  const { t } = useTranslation();
  const time = props.dateTime ? moment(props.dateTime).format('HH:mm:ss') : null;
  const date = props.dateTime ? moment(props.dateTime).format('DD/MM/YYYY') : null;

  return (
    <div className={classes.infoWindow}> 
      <div className={classes.infoWindowTitle}>
        CHECKOUT
      </div>
      <div className={classes.infoWindowSubTitle}>
        {props.address ? props.address : null}
      </div>
      <div>
        {time ? `${t('TIME')}: ${time}` : null}
      </div>
      <div>
        {date ? `${t('DATE')}: ${date}` : null}
      </div>
      <div>
        {props.serviceTime ? `${t('SERVICE_TIME')}: ${props.serviceTime}` : null}
      </div>
    </div>
  );
};

const CheckoutMark = (props: any) => {
  const LocationMarkStyle = {
    fill: props.showModal ? '#4994de' : '#2585e6',
    cursor: 'pointer',
  };

  return ( 
  <>
    <CheckoutLocationMark
      style={LocationMarkStyle}
      height='24px'
      width='24px'
      data-lat={props.lat}
      data-lng={props.lng}
    />
    {props.showModal ? <CheckoutInfoWindow
      data-lat={props.lat}
      data-lng={props.lng}
      address={props.address}
      dateTime={props.checkoutDateTime}
      serviceTime={props.serviceTime}
    /> : null}
  </>
)};


function exposeMap({ map }: { map: google.maps.Map }) {
  // @ts-ignore
  window.map = map;
}

export class Home extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      trackingID: getTrackingID(),
      accountID: this.props.accountId,
      visitExists: false,
      showModal: false
    };
  }

  onChildClickCallback = (showModal: boolean, key: string) => {
    if (key === 'checkout') {
      this.setState({
        showModal: showModal
      })
    }
  };

  searchByReference(accountId?: string, searchValue?: string) {
    auth().then(() => {
      const collection = firebase.firestore().collection('DeliveryTrack');
      collection
        .where('accountId', '==', Number(accountId))
        .where('reference', '==', searchValue)
        .get()
        .then(qs => {
          const visits: any = [];

          qs.forEach(v => visits.push(v.data()));

          if (qs.empty) {
            window.location.href = '/widget/not_found';
            return;
          }
          let max = visits[0];

          visits.forEach((x: any) => {
            if (
              x.currentStatus.date.toDate() > max.currentStatus.date.toDate()
            ) {
              max = x;
            }
          });
          this.props.gotTrackingID(max.trackingId, this.state.accountID);
          return;
        });
    });
  }

  componentDidMount() {
    if (this.state.trackingID === '') {
      return;
    }
    if (!this.state.trackingID.startsWith('SR')) {
      this.searchByReference(this.state.accountID, this.state.trackingID);
      return;
    }
    this.props.gotTrackingID(this.state.trackingID, this.state.accountID);
  }

  componentDidUpdate(prevState: Props) {
    function GeoPoint2Coords(point: firebase.firestore.GeoPoint): Coords {
      return {
        lat: point.latitude,
        lng: point.longitude,
      };
    }

    const prevVisit = prevState.store.visit && prevState.store.visit.visit;
    const visit = this.props.store.visit && this.props.store.visit.visit;
    const driver = this.props.store.driver && this.props.store.driver.driver;

    if (visit && !prevVisit) {
      const newCenter = GeoPoint2Coords(visit.location);
      this.setState({ center: newCenter });
    }

    if (!visit || !driver) {
      return;
    }

    fitBounds(
      GeoPoint2Coords(visit.location),
      GeoPoint2Coords(driver.location),
    );
  }

  render() {
    return (
      <div
        className={this.props.classes.root}
        id='map'
        style={{ height: '100%' }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_API_KEY || '' }}
          defaultCenter={SANTIAGO}
          center={defaultTo(SANTIAGO)(
            view<Coords, State>(
              lensPath<Coords, State>(['center']),
              this.state,
            ),
          )}
          defaultZoom={14}
          zoom={defaultTo(14)(this.state.zoom)}
          onGoogleApiLoaded={exposeMap}
          options={{
            zoomControlOptions: {
              position: 3,
            },
          }}
          yesIWantToUseGoogleMapApiInternals={true}
          onChildMouseEnter={(key) => this.onChildClickCallback(true, key)}
          onChildMouseLeave={(key) => this.onChildClickCallback(false, key)}
        >
          {this.props.store.visit.visit ? (
            <VisitMark
              lat={this.props.store.visit.visit.location.latitude}
              lng={this.props.store.visit.visit.location.longitude}
              key={'visit'}
            />
          ) : undefined}
          {this.props.store.driver.driver ? (
            <DriverMark
              lat={this.props.store.driver.driver.location.latitude}
              lng={this.props.store.driver.driver.location.longitude}
              key={'driver'}
            />
          ) : undefined}
          {(this.props.store.visit.visit && this.props.store.visit.visit.checkoutLatitude &&  this.props.store.visit.visit.checkoutLongitude) ? (
            <CheckoutMark
              lat={this.props.store.visit.visit.checkoutLatitude}
              lng={this.props.store.visit.visit.checkoutLongitude} 
              serviceTime={this.props.store.visit.visit.serviceTime}
              checkoutDateTime={this.props.store.visit.visit.checkoutDateTime}
              address={this.props.store.visit.visit.address}
              showModal={this.state.showModal}
              key={'checkout'}
          />
          ) : undefined}

        </GoogleMapReact>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  store: state,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    gotTrackingID: bindActionCreators(gotTrackingID, dispatch),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Home),
);
