import { createStyles, Theme, makeStyles } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      gridArea: '2/1/4/1',
      zIndex: 1,
    },
  });

export const infoWindowStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoWindow: {
      position: 'relative',
      bottom: 30,
      left: '30px',
      width: 160,
      backgroundColor: 'white',
      boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
      padding: 10,
      borderRadius: '3px',
      border: '1px solid #2585e6',
      fontSize: 12,
      zIndex: 100,
    },
    infoWindowTitle: {
      color: '#2585e6',
      fontWeight: 'bold',
    },
    infoWindowSubTitle: {
      fontSize: 10,
      borderBottom: '1px solid #b5b5b5',
      marginBottom: 5,
    }
  }),
);
