import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

export const theme = createMuiTheme({
  typography: {
    fontFamily: 'Open Sans, sans-serif',
  },
  overrides: {
    MuiStepConnector: {
      lineVertical: {
        minHeight: '0px',
      },
      vertical: {
        padding: '0 0 0',
      },
    },

    MuiStepContent: {
      root: {
        paddingBottom: '8px',
        marginTop: '0px',
      },
    },

    MuiPaper: {
      root: {
        marginTop: '32px',
        marginBottom: '32px'
      },
    },
    MuiStepper: {
      root: {
        padding: '0px',
      },
    },
    MuiStepIcon: {
      root: {
        color: '#cccccc',
        '&$active': {
          color: '#28baa4',
        },
        '&$completed': {
          color: '#cccccc',
        },
      },
    },
    MuiCardContent: {
      root: {},
    },
    MuiCard: {
      root: {
        gridArea: '3/1/5/1',
        alignSelf: 'end',
        zIndex: 2,
        borderRadius: '15px',
        border: '1px solid rgba(129, 157, 240, 1)',
      },
    },
    MuiCardActions: {
      root: {
        padding: 0,
      },
    },
    MuiCardHeader: {
      root: {
        height: '50px',
        gridArea: 'title',
        backgroundColor: '#819df0',
      },
      title: {
        fontSize: '20px',
        color: '#ffffff',
      },
    },
    MuiStep: {
      root: {
        padding: '5px 6px 2px 6px'
      }
    }
  },
});

export const useStyles = makeStyles({
  defaultColor: {
    color: '#cccccc !important',
  },
  successColor: {
    color: '#28baa4 !important',
  },
  failedColor: {
    color: '#ba0a0f !important',
  },
  defaultActiveBg: {
    backgroundColor: '#e9f3fc',
  },
  failedActiveBg: {
    backgroundColor: '#f7e9e9',
  },
  expandButton: {
    width: '100%',
  },
  cardWithoutOverflow: {
    [theme.breakpoints.down('xs')]: {
      maxHeight: 'unset !important',
      height: 'auto',
      margin: '5px auto',
      maxWidth: '100%',
    },
  },
  card: {
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    maxHeight: '40vh',
    marginLeft: 'auto',
    marginRight: '10px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      margin: '5px auto',
      maxWidth: '100%',
    },
  },
  cardContent: {
    overflow: 'auto',
    padding: '0 !important',
    height: 'calc(100% - 75px)',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      maxHeight: '150px',
      overflow: 'hidden',
      padding: '4px 5px !important',
      margin: '0 !important',
    },
  },
  stepper: {
    margin: '1px',
    [theme.breakpoints.down('xs')]: {
      padding: '5px 10px !important',
    },
  },
  stepLabel: {
    textTransform: 'capitalize',
    fontFamily: 'Open Sans',
    color: 'rgba(102, 102, 102, 1)',
  },
  stepContent: {
    fontFamily: 'Open Sans',
  },
});
