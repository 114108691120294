import {
  createStyles,
  makeStyles,
  createMuiTheme,
  Theme,
} from '@material-ui/core';
export const someTheme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      root: {
        backgroundImage: 'linear-gradient(225deg, #7546e2 0%, #24dfe8 100%)',
        typography: {
          fontFamily: 'Open Sans, sans-serif',
        },
      },
    },
  },
});

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      padding: 10,
      fontSize: '2rem !important',
    },
    smallIconButton: {
      padding: 5,
      fontSize: '1.5rem !important',
    },
    divider: {
      height: 28,
      margin: 4,
    },
    incorrectTextWide: {
      width: '100%',
      height: '50%',
      color: '#ffffff',
      fontSize: 15,
      textAlign: 'center',
      [someTheme.breakpoints.down('xs')]: {},
    },
    upperText: {
      padding: 5,
      fontSize: '40px',
      fontWeight: 600,
      fontFamily: 'sans-serif',
      [someTheme.breakpoints.down('xs')]: {
        fontSize: '20px',
      },
    },
    widgetUpperText: {
      padding: 5,
      fontSize: '20px',
      fontWeight: 600,
      fontFamily: 'sans-serif',
      [someTheme.breakpoints.down('xs')]: {
        fontSize: '10px',
      },
    },
    lowerText: {
      padding: 5,
      fontSize: '25px',
      fontFamily: 'sans-serif',
      [someTheme.breakpoints.down('xs')]: {
        fontSize: '16px',
      },
    },
    widgetLowerText: {
      padding: 5,
      fontSize: '15px',
      fontFamily: 'sans-serif',
      [someTheme.breakpoints.down('xs')]: {
        fontSize: '10px',
      },
    },
    logo: {
      width: 600,
      [someTheme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    input: {
      height: 60,
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    widgetInput: {
      height: 35,
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    inputContainer: {
      display: 'flex',
      width: '50%',
      [someTheme.breakpoints.down('xs')]: {
        width: '90%',
      },
    },
    mainContainer: {
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      backgroundImage: 'linear-gradient(#819df0, #24dfe8)',
      [someTheme.breakpoints.down('xs')]: {
        width: 'auto',
      },
    },
    topContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [someTheme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    footContainer: {
      display: 'flex',
      position: 'fixed',
      marginTop: '30px',
      alignItems: 'center',
      fontSize: '1.5rem !important',
      [someTheme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  }),
);
