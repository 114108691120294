import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { useStyles, someTheme } from './styles';
import logo from '../../assets/images/LiveTracking3.svg';

interface NotFoundProps { }
  
export function NotFoundPage({ }: NotFoundProps) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [message, setMessage] = useState('');

    return (<MuiThemeProvider theme={someTheme}>
        <div className={classes.mainContainer}>
          <div className={classes.topContainer}>
            <img alt="logo" onLoad={ () => setMessage(t('INCORRECT_CODE')) } src={logo} className={classes.logo} />
            <div className={classes.incorrectTextWide}>
                <div className={classes.upperText}>{message}</div>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
}