import {
  all,
  fork,
  takeEvery,
  call,
  put,
  select,
  take,
} from 'redux-saga/effects';

import firebase from 'firebase/app';

import {
  auth,
  createGotDriverByDriverIDChannel,
  claimTrackingID,
  createGotVisitChannel,
  getVisits,
  NO_DATA,
} from '../../../algorithms/firebase.utils';

export enum HOME_ACTIONS {
  DO_AUTHENTICATION = 'DO_AUTHENTICATION',
}

export enum HOME_EVENTS {
  AUTHENTICATED = 'AUTHENTICATED',
  GOT_TRACKING_ID = 'GOT_TRACKING_ID',
  GOT_VISIT = 'GOT_VISIT',
  GOT_DRIVER = 'GOT_DRIVER',
  DRIVER_CHANNEL_CLOSED = 'DRIVER_CHANNEL_CLOSED',
  VISIT_CHANNEL_CLOSED = 'VISIT_CHANNEL_CLOSED',
  GOT_MAP_OPTIONS = 'GOT_MAP_OPTIONS',
  GOT_VISITS = 'GOT_VISITS',
  VISITS_CHANNEL_CLOSED = 'VISITS_CHANNEL_CLOSED',
  SEND_VISITS_STORE = 'SEND_VISITS_STORE',
}

// ----------ACTION CREATORS------------

export function authenticated() {
  return {
    type: HOME_EVENTS.AUTHENTICATED,
  };
}

export function doAuthentication() {
  return {
    type: HOME_ACTIONS.DO_AUTHENTICATION,
  };
}

export function gotTrackingID(trackingID: string, accountID?: string) {
  return {
    type: HOME_EVENTS.GOT_TRACKING_ID,
    payload: {
      trackingID,
      accountID,
    },
  };
}

export function gotVisit(visit: any) {
  return {
    type: HOME_EVENTS.GOT_VISIT,
    payload: visit,
  };
}

export function gotVisits() {
  return {
    type: HOME_EVENTS.GOT_VISITS,
  };
}

export function sendVisitsToStore(visits: any) {
  return {
    type: HOME_EVENTS.SEND_VISITS_STORE,
    payload: visits,
  };
}

export function gotDriver(driver: Driver) {
  return {
    type: HOME_EVENTS.GOT_DRIVER,
    payload: driver,
  };
}

export function driverChannelClosed() {
  return {
    type: HOME_EVENTS.DRIVER_CHANNEL_CLOSED,
  };
}

export function visitChannelClosed() {
  return {
    type: HOME_EVENTS.VISIT_CHANNEL_CLOSED,
  };
}

export function visitsChannelClosed() {
  return {
    type: HOME_EVENTS.VISITS_CHANNEL_CLOSED,
  };
}

export function gotMapOptions(mapOptions: any) {
  return {
    type: HOME_EVENTS.GOT_MAP_OPTIONS,
    payload: mapOptions,
  };
}

// ----------STORE SELECTORS------------
export const selectTrakingID = (store: RootStore) => store.login.trackingID;
export const selectDriverId = (store: RootStore) =>
  store.visit.visit && store.visit.visit.driverId;

export const selectSearchAccountId = (store: RootStore) =>
  store.login.accountID;

export const selectAccountId = (store: RootStore) =>
  store.visit.visit && store.visit.visit.accountId;

export const selectReference = (store: RootStore) =>
  store.visit.visit && store.visit.visit.reference;

export const selectTrackinId = (store: RootStore) =>
  store.visit.visit && store.visit.visit.trackingId;

// ----------SAGAS------------

export function* doAuthenticationSaga() {
  yield call(auth);
  yield put(authenticated());
}

export function* gotTrackingIDSaga() {
  yield put(doAuthentication());
}

export function* getVisitSaga() {
  const trackingID: string = yield select(selectTrakingID);
  const searchAccountId: string = yield select(selectSearchAccountId);

  const channel = yield call(
    createGotVisitChannel,
    trackingID,
    searchAccountId,
  );
  try {
    while (true) {
      const visit = yield take(channel);
      if (visit === NO_DATA) {
        window.location.href = '/widget/not_found';
        return;
      }
      yield put(gotVisit(visit));

      yield put(gotVisits());
    }
  } finally {
    yield put(visitChannelClosed());
  }
}

export function* getVisitsSaga() {
  const account = yield select(selectAccountId);
  const trackingId = yield select(selectTrackinId);
  const reference = yield select(selectReference);

  const callingVisits = yield call(getVisits, account, trackingId, reference);
  yield put(sendVisitsToStore(callingVisits));
}

export function* getDriverSaga() {
  // https://github.com/redux-saga/redux-saga/blob/master/docs/advanced/Channels.md
  const driverId: number = yield select(selectDriverId);

  if (!driverId) {
    return;
  }

  const trackingID: string = yield select(selectTrakingID);
  const claim = yield call(claimTrackingID, trackingID);

  if (!claim) {
    return;
  }

  const channel = yield call(createGotDriverByDriverIDChannel, driverId);

  try {
    while (true) {
      const driver = yield take(channel);
      yield put(gotDriver(driver));
    }
  } finally {
    yield put(driverChannelClosed());
  }
}

// ----------ROOT SAGAS------------

export function* homeSaga() {
  yield takeEvery(HOME_EVENTS.GOT_TRACKING_ID, gotTrackingIDSaga);
  yield takeEvery(HOME_ACTIONS.DO_AUTHENTICATION, doAuthenticationSaga);
  yield takeEvery(HOME_EVENTS.AUTHENTICATED, getVisitSaga);
  yield takeEvery(HOME_EVENTS.GOT_VISIT, getDriverSaga);
  yield takeEvery(HOME_EVENTS.GOT_VISITS, getVisitsSaga);
}

export function* rootSaga() {
  yield all([fork(homeSaga)]);
}

// ----------REDUCERS------------

export interface LoginStore {
  authenticated: boolean;
  trackingID?: string;
  accountID?: string;
}

export interface Date {
  seconds: number;
}

export interface StatusHistory {
  status: string;
  date: Date;
}
export interface Status {
  date: Date;
  status: string;
}

export interface Item {
  id: number;
  load?: number;
  load2?: number;
  load3?: number;
  notes?: string;
  quantityDelivered: number;
  quantityPlanned: number;
  status: string;
  statusChanged?: string;
  title?: string;
}

export interface Visit {
  driverId: number;
  currentStatus: Status;
  location: firebase.firestore.GeoPoint;
  trackingID: string;
  trackingId?: string;
  title: string;
  routeId: string;
  visitId: number;
  accountId: number;
  accountName: string;
  address: string;
  contactName?: string;
  contactPhone?: string;
  contactEmail?: string;
  checkoutDateTime?: string;
  checkoutLatitude?: number;
  checkoutLongitude?: number;
  serviceTime?: string;
  extrafields_value?: VisitExtraFields[];
  reference?: string;
  signature?: string;
  images: string[];
  statusHistory: StatusHistory[];
  onItsWayTimeRange: Date[];
  routeStartTimeRange: Date[];
  plannedDate: Date;
  items: Item[];
}

export interface VisitExtraFields {
  label: string;
  type: string;
  value: any;
}

export interface VisitStore {
  visit?: Visit;
}
export interface VisitsStore {
  visits?: Visit[];
}
interface Driver {
  driverId: number;
  location: firebase.firestore.GeoPoint;
}
export interface DriverStore {
  driver?: Driver;
}
export interface RootStore {
  login: LoginStore;
  visit: VisitStore;
  driver: DriverStore;
  visits: VisitsStore;
}
export function loginReducer(
  state: LoginStore = { authenticated: false },
  action: any,
) {
  switch (action.type) {
    case HOME_EVENTS.AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
      };
    case HOME_EVENTS.GOT_TRACKING_ID:
      return {
        ...state,
        trackingID: action.payload.trackingID,
        accountID: action.payload.accountID,
      };
    default:
      return state;
  }
}
export function visitsReducer(
  state: VisitsStore = {},
  action: any,
): VisitsStore {
  switch (action.type) {
    case HOME_EVENTS.SEND_VISITS_STORE:
      return {
        visits: action.payload,
      };
    default:
      return state;
  }
}
export function visitReducer(state: VisitStore = {}, action: any): VisitStore {
  switch (action.type) {
    case HOME_EVENTS.GOT_VISIT:
      return {
        visit: action.payload,
      };
    default:
      return state;
  }
}

export function driverReducer(
  state: DriverStore = {},
  action: any,
): DriverStore {
  switch (action.type) {
    case HOME_EVENTS.GOT_DRIVER:
      return {
        driver: action.payload,
      };
    case HOME_EVENTS.DRIVER_CHANNEL_CLOSED:
      return {
        driver: undefined,
      };
    default:
      return state;
  }
}
