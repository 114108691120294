import React, { useState } from 'react';

import { useStyles, theme } from './styles';
import { useMediaQuery } from '@material-ui/core';

interface CompanyImageProps {
  visit: any;
}

export function CompanyImage({ visit }: CompanyImageProps) {
  const classes = useStyles();
  let urlImage = 'https://simpli-visit-images.s3.amazonaws.com/logos/';
  let canRender = false;

  const [showBorder, setShowBorder] = useState(true);

  if (visit !== undefined) {
    urlImage += visit.accountId.toString() + '.png';
    canRender = true;
  } else {
    canRender = false;
    urlImage = '';
  }

  let objectNotFound = () => {
    setShowBorder(false);
  };

  return (
    <div className={classes.logo}>
      {canRender ? (
        <object
          className={classes.image}
          style={showBorder ? {} : { border: 0 }}
          data={urlImage}
          type="image/jpg"
        />
      ) : (
        <React.Fragment />
      )}
    </div>
  );
}
