import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { text } from './text';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'es',
    debug: true,
    resources: text,
    interpolation: {
      escapeValue: false,
    },
  })
  .catch(e => {
    console.log('oh no', e);
  });

export default i18n;
