import { Coords } from 'google-map-react';

export const SIMPLI_HQ = {
  lat: -33.4200596,
  lng: -70.604137,
};

export const SANTIAGO: Coords = {
  lat: -33.447487,
  lng: -70.673676,
};
