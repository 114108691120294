import { createMuiTheme, makeStyles } from '@material-ui/core';

export const defaultTheme = createMuiTheme();

export const theme = createMuiTheme({
  typography: {
    fontFamily: 'Open Sans, sans-serif',
  },
  overrides: {
    MuiPaper: {
      root: {
        marginTop: '32px',
        marginRight: '0px',
        marginBottom: '32px',
      },
    },
    MuiCardContent: {
      root: {
        gridTemplateAreas: `
        "address_title images"
        "address       images"
        "items         images"
        "items_detail  images"
        "items_button  images"
        "contact_title images"
        "contact_email images"
        "contact_phone images"
        "rest images"
        `,
        [defaultTheme.breakpoints.down('xs')]: {
          gridTemplateAreas: `
          "address_title"
          "address"
          "items"
          "items_detail"
          "items_button"
          "contact_title"
          "contact_email"
          "contact_phone"
          "images"
          `,
          '&:last-child': {
            paddingBottom: 0,
          },
        },
      },
    },
    MuiCard: {
      root: {
        gridArea: '3/1/5/1',
        alignSelf: 'end',
        zIndex: 2,
        borderRadius: '15px',
        border: '1px solid rgba(129, 157, 240, 1)',
      },
    },
    MuiCardHeader: {
      root: {
        height: '50px',
        gridArea: 'title',
        backgroundColor: '#819df0',
      },
      title: {
        fontSize: '20px',
        color: '#ffffff',
      },
    },
  },
});

export const useStyles = makeStyles({
  card: {
    width: '100%',
    maxWidth: '90%',
    height: '100%',
    maxHeight: '40vh',
    marginLeft: 'auto',
    marginRight: '-1px',
    overflow: 'auto',

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: 'unset',
      margin: '5px auto',
      maxWidth: '100%',
    },
  },
  cardContent: {
    overflowY: 'auto',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      overflow: 'auto',
    },
  },
  address_title: {
    gridArea: 'address_title',
    fontSize: '14px',
    color: '#95989a',
    [defaultTheme.breakpoints.down('xs')]: {
      color: '#666666',
      fontSize: '10px',
    },
  },
  address: {
    gridArea: 'address',
    color: '#2f2f2f',
    wordBreak: 'break-all',
    fontSize: '0.875rem',
    display: 'block',
  },
  contact_title: {
    gridArea: 'contact_title',
    fontSize: '14px',
    color: '#95989a',
    [defaultTheme.breakpoints.down('xs')]: {
      color: '#666666',
      fontSize: '10px',
    },
  },
  other_values: {
    gridArea: 'contact_title',
    fontSize: '14px',
    color: '#95989a',
    [defaultTheme.breakpoints.down('xs')]: {
      color: '#666666',
      fontSize: '10px',
    },
  },  
  items_title: {
    gridArea: 'items',
    margin: '0px', 
    padding: '5px 5px 0px 5px',
    fontSize: '14px',
    color: '#95989a',
    display: 'block',
    [defaultTheme.breakpoints.down('xs')]: {
      color: '#666666',
      fontSize: '10px',
    },
  },
  items_detail: {
    gridArea: 'items_detail',
    fontSize: '12px',
    margin: '0px', 
    padding: '0px 5px 5px 5px',
    display: 'block',
    [defaultTheme.breakpoints.down('xs')]: {
      color: '#666666',
      fontSize: '10px',
    },
  },
  items_button: {
    marginLeft: '5px',
    gridArea: 'items_button',
    marginBottom: '10px',
    display: 'flex',
    fontSize: '10px'
  },
  items_button_icon: {
    paddingRight: '5px'
  },
  contact_email: {
    color: '#2f2f2f',
    fontSize: '16px',
    wordBreak: 'break-all',
    [defaultTheme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  contact_email_icon: {
    color: '#666666',
    verticalAlign: 'middle',
    [defaultTheme.breakpoints.down('xs')]: {
      width: '12px',
      height: '12px',
    },
  },
  contact_phone: {
    gridArea: 'contact_phone',
    color: '#2f2f2f',
    fontSize: '14px',
    [defaultTheme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  contact_phone_icon: {
    verticalAlign: 'middle',
    color: '#666666',
    [defaultTheme.breakpoints.down('xs')]: {
      width: '12px',
      height: '12px',
    },
  },
  extrafield_list: {
    color: '#666666',
    paddingTop: '0px',
    paddingBottom: '30px',
    marginBottom: '10px',
  },
  extrafield_list_item: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  extrafield_list_text: {
    color: '#2f2f2f',
    wordBreak: 'break-word',
    fontSize: '0.875rem',
    fontFamily: 'Lato, sans-serif',
  },
  extrafield_list_link: {
    color: '#1E90FF',
    fontSize: '0.875rem',
    fontFamily: 'Lato, sans-serif',
  },
  items_detail_container: {
    width: '500px',
    [defaultTheme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  items_header: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#819df0'
  },
  items_header_text: {
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
  },
  items_header_title: {
    fontWeight: 'bold',
    fontSize: '16px',
    margin: '10px 0px 0px 15px',
  },
  items_header_subtitle: {
    margin: '0px 0px 10px 20px',
  },
  items_close_dialog: {
    color: 'white',
    margin: '20px 20px 20px 0px',
    cursor: 'pointer',
  },
  items_content_container: {
    display: 'flex',
    flexDirection: 'column',
  },
  items_table_header: {
    display: 'flex',
    margin: '10px 20px 0px 20px'
  },
  items_table_row: {
    display: 'flex',
    margin: '4px 20px 0px 20px'
  },
  items_table_footer: {
    display: 'flex',
    margin: '4px 20px 20px 20px'
  },
  column_2_header: {
    flex: '3',
    fontSize: '10px',
    paddingLeft: '10px'
  },
  column_1_header: {
    flex: '1',
    fontSize: '10px',
    marginLeft: '4px',
    textAlign: 'center',
  },
  column_2_content: {
    flex: '3',
    backgroundColor: '#e6e6e6',
    padding: '5px 0px 5px 5px',
  },
  column_1_content: {
    flex: '1',
    backgroundColor: '#e6e6e6',
    marginLeft: '4px',
    padding: '5px 0px 5px 5px',
    textAlign: 'center',
  },
  column_2_footer: {
    flex: '3',
    padding: '5px 0px 5px 5px',
    fontWeight: 'bold',
  },
  column_1_footer: {
    fontWeight: 'bold',
    flex: '1',
    backgroundColor: '#7dc5ff',
    marginLeft: '4px',
    padding: '5px 0px 5px 5px',
    textAlign: 'center',
  },
});
