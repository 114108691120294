import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withRoot from '../withRoot';
import styles from './styles';
import { StylesProvider, createGenerateClassName } from '@material-ui/styles';
import {
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import StateView from '../components/StateView/StateView';
import { LandingPage } from '../components/landingPage/LandingPage';
import { Switch, Route } from 'react-router-dom';
import { doAuthentication } from '../components/Home/sagas';
import { NotFoundPage } from '../components/notFoundPage/NotFoundPage';

const someTheme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      root: {
        backgroundImage: 'linear-gradient(#819DF0, #24DFE8)',
      },
    },
  },
});

const generateClassName = createGenerateClassName({
  productionPrefix: 'prod',
  seed: 'live-tracking',
});

class App extends Component {
  render() {
    return (
      <StylesProvider generateClassName={generateClassName}>
        <MuiThemeProvider theme={someTheme}>
          <Switch>
            <Route
              path="/:trackingId(SR[0-9]+)"
              render={r => {
                return <StateView trackingId={r.match.params.trackingId} />;
              }}
            />            
            <Route
              path="/:accountId([0-9]+)"
              render={r => {
                return (
                  <LandingPage
                    history={r.history}
                    accountId={r.match.params.accountId}
                  />
                );
              }}
            />
             <Route
              path="/widget/account/:accountId/tracking/:trackingId"
              render={r => {
                return (<StateView accountId={r.match.params.accountId} trackingId={r.match.params.trackingId}></StateView>)              
              }}
            />
            <Route
              path="/widget/not_found"
              render={r => {
                return (
                  <NotFoundPage/>
                );
              }}
            />
          </Switch>
        </MuiThemeProvider>
      </StylesProvider>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  doAuthentication: bindActionCreators(doAuthentication, dispatch),
});

const mapStateToProps = (state: any) => ({
  store: state,
});

export default withRoot(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App)),
);
