import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { eventChannel, END } from 'redux-saga';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
};

export const NO_DATA = 'NO_DATA';

firebase.initializeApp(firebaseConfig);

export async function auth() {
  await firebase.auth().signInAnonymously();
}

export function checkAccountId(accountId: number) {
  const collection = firebase.firestore().collection('DeliveryTrack');
  return collection.where('accountId', '==', accountId);
}

export function getVisits(
  accountId: number,
  trackingId: string,
  reference: string,
) {
  const visits: any[] = [];

  const collection = firebase.firestore().collection('DeliveryTrack');

  if (!reference) {
    return collection
      .where('trackingId', '==', trackingId)
      .get()
      .then(snapShot => {
        snapShot.forEach(doc => {
          visits.push(doc.data());
        });
        return visits;
      })
      .catch(error => {
        console.log(error);
        alert('Error al obtener visitas');
        return visits;
      });
  }
  return collection
    .where('accountId', '==', accountId)
    .where('reference', '==', reference)
    .get()
    .then(snapShot => {
      snapShot.forEach(doc => visits.push(doc.data()));
      return visits;
    })
    .catch(error => {
      console.log(error);
      alert('Error al obtener visitas');
      return visits;
    });
}

export function createGotVisitChannel(trackingID: string, searchAccountId?: string) {
  return eventChannel(emit => {

    let firebaseQuery = firebase
      .firestore()
      .collection('DeliveryTrack')
      .where('trackingId', '==', trackingID);

    if (searchAccountId) {
      firebaseQuery = firebaseQuery.where('accountId', '==', Number(searchAccountId));
    }

    return firebaseQuery.onSnapshot(
        gps => {
          try {
            const data = gps.docs[0].data();
            if (!data) {
              return;
            }
            emit(data);
          } catch {
            emit(NO_DATA);
            emit(END);
          }

        },
        () => {
          emit(END);
        },
      );
  });
}

export function createGotDriverByDriverIDChannel(driverID: number) {
  return eventChannel(emit => {
    return firebase
      .firestore()
      .collection('TrackingGPS')
      .doc(driverID.toString())
      .onSnapshot(
        gps => {
          const data = gps.data();
          if (!data) {
            return;
          }
          emit(data);
        },
        () => {
          emit(END);
        },
      );
  });
}

export async function claimTrackingID(trackingID: string): Promise<boolean> {
  return firebase
    .functions()
    .httpsCallable('login')({ trackingID })
    .then(async () => {
      const user = firebase.auth().currentUser;
      if (user) {
        await user.getIdTokenResult(true);
      }

      return true;
    })
    .catch(() => {
      return false;
    });
}
