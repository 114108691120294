import { combineReducers } from 'redux';
import tasks from './tasks';
import {
  loginReducer as login,
  visitReducer as visit,
  driverReducer as driver,
  visitsReducer as visits,
} from '../components/Home/sagas';

export default combineReducers({
  tasks,
  login,
  visit,
  visits,
  driver,
});
