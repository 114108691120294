import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    flex: {
      flex: 1,
      gridArea: 'header',
      textAlign: 'right',
      fontWeight: 'normal',
      marginRight: '10px',
      fontSize: '18px',
    },
    cardsContainerFull: {
      height: '100%',
      padding: '10px',
      maxHeight: 'unset',
      overflow: 'auto',
    },
    cardsContainerRow: {
      padding: '3vh 5vh 3vh 5vh',
      overflow: 'auto',
      maxHeight: '50vh',
      background: '#FFFFFF',
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        maxHeight: 'unset',
        padding: '1vh 1vh',
        width: '100%',
        overflow: 'auto',
        zIndex: 1,
        bottom: 0,
        background: 'transparent',
      },
    },
    container: {},
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    root: {
      flexGrow: 1,
      display: 'grid',
      gridTemplateRows: '64px 1fr 1fr',
      height: '100vh',
      width: '100vw',
    },
    logo: {
      height: '50%',
    },
  });

export default styles;
