import React, { useState } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { CardHeader, CardContent, Button, Dialog } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import { useTranslation } from 'react-i18next';
import { Visit, VisitExtraFields } from '../Home/sagas';
import { SingleLineGridList } from '../imagesList/imageList';
import { DownloadImages } from '../imagesList/downloadImages';
import { theme, useStyles } from './styles';

interface SimpleCardProps {
  visit: Visit;
}

export function SimpleCard({ visit }: SimpleCardProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const acceptedExtrafields: string[] = ['NUM', 'STX', 'LTX', 'DAT', 'PIC'];
  const [showItemsModal, setItemsModal] = useState(false);

  let extrafieldsToShow: Visit['extrafields_value'] = [];

  if (visit.extrafields_value) {
    extrafieldsToShow = filterAcceptedExtrafields(
      visit.extrafields_value,
      acceptedExtrafields,
    );
  }

  let openItemsModal = () => setItemsModal(true);
  let closeItemsModal = () => setItemsModal(false);

  let plannedItems = 0;
  let deliveredItems = 0;

  if (visit.items && visit.items.length) {
    visit.items.forEach(item => {
      plannedItems += item.quantityPlanned;
      deliveredItems += item.quantityDelivered;
    });
  }

  const ItemsDetail = () => {
    return (
      <div className={classes.items_detail_container}>
        <div className={classes.items_header}>
          <div className={classes.items_header_text}>
            <span className={classes.items_header_title}>{t('ITEMS_DISPATCHED')}</span>
            <span className={classes.items_header_subtitle}>{`${deliveredItems} ${t('ITEMS_SUMMARY')} ${plannedItems}`}</span>
          </div>
          <div className={classes.items_close_dialog} onClick={() => closeItemsModal()}>
            <CloseIcon fontSize="default"/>
          </div>
        </div>
        <div className={classes.items_content_container}>
          <div className={classes.items_table_header}>
            <div className={classes.column_2_header}>
              {t('ITEM')}
            </div>
            <div className={classes.column_1_header}>
              {t('QUANTITY_PLANNED')}
            </div>
            <div className={classes.column_1_header}>
            {t('QUANTITY_DELIVERED')}
            </div>
          </div>
          {visit.items.map((item) => (
            <div key={item.id} className={classes.items_table_row}>
              <div className={classes.column_2_content}>
                {item.title}
              </div>
              <div className={classes.column_1_content}>
                {item.quantityPlanned}
              </div>
              <div className={classes.column_1_content}>
                {item.quantityDelivered}
              </div>
            </div>
          ))}
          <div className={classes.items_table_footer}>
            <div className={classes.column_2_footer}>
              {t('TOTAL')}
            </div>
            <div className={classes.column_1_footer}>
              {plannedItems}
            </div>
            <div className={classes.column_1_footer}>
              {deliveredItems}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const generateExtrafieldsList = (
    extrafieldsArray: Visit['extrafields_value'],
  ) => {
    let extraFieldList;

    if (extrafieldsArray) {
      extraFieldList = extrafieldsArray.map((item: VisitExtraFields) => {
        if (item.type === 'PIC') {
          return (
            <ListItem key={item.label} className={classes.extrafield_list_item}>
              <ListItemText>
                <Typography
                  variant="caption"
                  className={classes.extrafield_list_text}>
                  {`${item.label}: `}
                </Typography>
                <Link
                  href={item.value}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.extrafield_list_text}>
                  <Typography
                    variant="caption"
                    className={classes.extrafield_list_link}>
                    LINK
                  </Typography>
                </Link>
              </ListItemText>
            </ListItem>
          );
        } else {
          return (
            <ListItem key={item.label} className={classes.extrafield_list_item}>
              <ListItemText>
                <Typography
                  variant="caption"
                  className={classes.extrafield_list_text}>
                  {`${item.label}: ${item.value}`}
                </Typography>
              </ListItemText>
            </ListItem>
          );
        }
      });
    }

    return <List className={classes.extrafield_list}>{extraFieldList}</List>;
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Card className={classes.card}>
        <CardHeader data-cy="visit-detail.title" title={t('Title')} />
        <CardContent className={classes.cardContent}>
          <Grid container={true}>
            <Grid item={true} xs={6}>
              <Typography
                variant="caption"
                data-cy="visit-detail.address-title"
                className={classes.address_title}
                style={{ margin: '0px', padding: '5px' }}>
                {t('AddressTitle')}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                gutterBottom={true}
                className={classes.address}
                data-cy="visit-detail.address"
                style={{ margin: '0px', padding: '5px' }}>
                {visit.address}
              </Typography>
              {visit.items && visit.items.length ? <Typography
                variant="caption"
                data-cy="visit-detail.items"
                className={classes.items_title}>
                {t('ITEMS')}
              </Typography>  : null}
              {visit.items && visit.items.length ? <Typography
                  variant="caption"
                  data-cy="visit-detail.items_detail"
                  className={classes.items_detail}>
                  {`${deliveredItems} ${t('ITEMS_SUMMARY')} ${plannedItems}`}
              </Typography> : null}
              {visit.items && visit.items.length ? <Button
                  onClick={() => openItemsModal()}
                  size="small"
                  variant="contained"
                  color="primary"
                  data-cy="visit-detail.items_button"
                  className={classes.items_button}>
                  <ReceiptIcon fontSize="small" className={classes.items_button_icon}/> {t('SHOW_ITEMS')}
              </Button> : null}
              <Dialog
                open={showItemsModal}
                onClose={closeItemsModal}
              >
                <ItemsDetail />
              </Dialog>
              <Typography
                variant="caption"
                className={classes.contact_title}
                data-cy="visit-detail.contact-title"
                style={{ margin: '0px', padding: '5px' }}>
                {t('ContactTitle')}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                className={classes.contact_email}
                data-cy="visit-detail.contact-email"
                style={{ margin: '0px', padding: '5px' }}>
                <EmailIcon
                  className={classes.contact_email_icon}
                  style={{ margin: '0px', padding: '5px' }}
                />
                {visit.contactEmail}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                gutterBottom={true}
                className={classes.contact_phone}
                data-cy="visit-detail.contact-phone"
                style={{ margin: '0px', padding: '5px' }}>
                <PhoneIcon
                  className={classes.contact_phone_icon}
                  style={{ margin: '0px', paddingBottom: '0px' }}
                />
                {visit.contactPhone}
              </Typography>
              {extrafieldsToShow && extrafieldsToShow.length ? (
                <Typography
                  variant="caption"
                  data-cy="visit-detail.other-values"
                  className={classes.other_values}
                  style={{ margin: '0px', padding: '5px' }}>
                  {t('OtherValues')}
                </Typography>
              ) : null}
              {extrafieldsToShow && extrafieldsToShow.length
                ? generateExtrafieldsList(extrafieldsToShow)
                : null}
            </Grid>
            <Grid item={true} xs={6}>
              {visit.images.length || visit.signature ? (
                <DownloadImages visit={visit} />
              ) : null}
              <SingleLineGridList visit={visit} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </MuiThemeProvider>
  );
}

export const filterAcceptedExtrafields = (
  allExtrafields: Visit['extrafields_value'],
  acceptedExtrafields: string[],
) => {
  let extrafieldsResult;

  if (allExtrafields) {
    extrafieldsResult = allExtrafields.filter(
      extrafield =>
        acceptedExtrafields.includes(extrafield.type) &&
        extrafield.value !== null &&
        typeof extrafield.value !== 'object',
    );
  }

  return extrafieldsResult;
};
