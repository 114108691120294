import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Status } from '../../utils/enums';
import { Card, CardContent, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-au';
import 'moment/locale/pt-br';
import { theme, useStyles, BorderLinearProgress } from './styles';
import { Visit } from '../Home/sagas';

moment.locale(navigator.language);

interface StatusProgressBarProps {
  visit?: Visit;
}

export function StatusProgressBar({ visit }: StatusProgressBarProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'));

  let trackId = '';
  let actualDate = '';
  let actualDateCheckout = '';
  let actualStatus = '';
  let numberOfStatus = 0;
  let onItsWayStart = '';
  let onItsWayEnd = '';
  let routeStart = '';
  let routeEnd = '';
  let planned = '';
  let conciseStatus = '';
  let showDate = '';

  if (visit !== undefined) {
    if (visit.reference) {
      trackId = visit.reference;
    } else if (visit.trackingId !== undefined) {
      trackId = visit.trackingId;
    }
    actualDate = moment(
      new Date(visit.currentStatus.date.seconds * 1000),
    ).format('LL');
    actualDateCheckout = moment(
      new Date(visit.currentStatus.date.seconds * 1000),
    ).format('LT');

    actualStatus = t(visit.currentStatus.status);

    switch (visit.currentStatus.status) {
      case Status.created:
        conciseStatus = t('CONCISE_CREATED');
        numberOfStatus = 1;
        if (visit.plannedDate !== undefined) {
          planned =
            moment(new Date(visit.plannedDate.seconds * 1000)).format(
              'DD MMM, YYYY',
            ) || ' ';
        }
        showDate = planned;
        break;
      case Status.routeStarted:
        conciseStatus = t('CONCISE_ROUTE_STARTED');
        numberOfStatus = 2;
        if (visit.routeStartTimeRange !== undefined) {
          routeStart =
            moment(
              new Date(visit.routeStartTimeRange[0].seconds * 1000),
            ).format('LT') || ' ';

          routeEnd =
            moment(
              new Date(visit.routeStartTimeRange[1].seconds * 1000),
            ).format('LT') || ' ';
        }
        showDate = ` ${t('FROM')} ${routeStart} ${t('AND')} ${routeEnd}`;
        break;
      case Status.onItsWay:
        conciseStatus = t('CONCISE_ON_ITS_WAY');
        numberOfStatus = 3;
        if (visit.onItsWayTimeRange !== undefined) {
          onItsWayStart =
            moment(new Date(visit.onItsWayTimeRange[0].seconds * 1000)).format(
              'HH:mm',
            ) || ' ';
          onItsWayEnd =
            moment(new Date(visit.onItsWayTimeRange[1].seconds * 1000)).format(
              'HH:mm',
            ) || ' ';
        }
        showDate = ` ${t('FROM')} ${onItsWayStart} ${t('AND')} ${onItsWayEnd}`;
        break;
      case Status.checkoutSuccess:
        conciseStatus = t('CONCISE_CHECKOUT_SUCCESS');
        numberOfStatus = 4;
        showDate = actualDateCheckout;
        break;
      case Status.checkoutFailed:
        conciseStatus = t('CONCISE_CHECKOUT_FAILED');
        numberOfStatus = 4;
        showDate = actualDateCheckout;
        break;
      default:
        conciseStatus = ' ';
        numberOfStatus = 0;
        showDate = '';
    }
  }
  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.progressBarContainer}>
        <Card className={classes.progressBar}>
          <Grid
            container={true}
            direction={'row'}
            justify={'space-between'}
            alignItems={'flex-end'}
          >
            {!isMobileDevice ? (
              <div id={'order-label'} className={classes.orderLabel}>{`${t(
                'ORDER',
              )} #${trackId}`}</div>
            ) : (
              <React.Fragment />
            )}
            <div className={classes.orderInfo}>
              <div className={classes.text}>{conciseStatus}</div>
              <div className={classes.status}>{showDate}</div>
            </div>
          </Grid>

          {!isMobileDevice && (
            <React.Fragment>
              <CardContent>
                <div>
                  <BorderLinearProgress
                    variant='determinate'
                    color='primary'
                    value={numberOfStatus * 25}
                  />
                </div>
              </CardContent>
              <div
                style={{
                  marginRight: '16px',
                  marginLeft: '16px',
                  marginBottom: '5px',
                  marginTop: '0px',
                }}
              >
                <Grid
                  container={true}
                  direction={'row'}
                  justify={'space-between'}
                  alignItems={'flex-end'}
                >
                  <div style={{ fontSize: '12px', color: '#666666' }}>
                    {actualDate}
                  </div>
                  <div style={{ fontSize: '12px', color: '#666666' }}>
                    {actualStatus}
                  </div>
                </Grid>
              </div>
            </React.Fragment>
          )}
        </Card>
      </div>
    </MuiThemeProvider>
  );
}
