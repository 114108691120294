import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

export const theme = createMuiTheme({});

export const useStyles = makeStyles({
  logo: {
    position: 'absolute',
    top: 24,
    left: '5vh',
    [theme.breakpoints.down('xs')]: {
      top: 24,
      left: '5vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100px',
      width: '100px',
    },
  },
  image: {
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('xs')]: {
      width: '100px',
    },
  },
});
