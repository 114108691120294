import React, { ReactNode, ReactNodeArray, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-au';
import 'moment/locale/pt-br';

import { ExpandMore, ExpandLess, CheckCircle, Cancel } from '@material-ui/icons';
import { MuiThemeProvider } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
} from '@material-ui/core';

import { Visit } from '../Home/sagas';
import { Status } from '../../utils/enums';
import { useStyles, theme } from './styles';

moment.locale(navigator.language);

interface MultipleStatusCardsProps {
  visits: Visit[];
  shouldToggleStatusView: Function;
  mobileViewDisplayAll: Function;
}

export function SimpleStatusCard({
  visits,
  mobileViewDisplayAll,
  shouldToggleStatusView,
}: MultipleStatusCardsProps) {
  const classes = useStyles();
  const history: any[] = [];
  const { t } = useTranslation();
  const [displayAll, toggleDisplayAll] = useState(false);

  visits.forEach(visit => {
    visit.statusHistory.forEach(status => {
      history.push(status);
    });
  });
  if (history.length === 1) {
    shouldToggleStatusView(true);
  }
  let isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'));

  let onMobileDisplayStatus = () => {
    toggleDisplayAll(!displayAll);
    mobileViewDisplayAll(!displayAll);
  };

  const setStatus = (status: string, colored: boolean) => {
    switch (status) {
      case Status.checkoutSuccess:
        return {
          icon: <CheckCircle className={colored ? classes.successColor : classes.defaultColor} />,
          activeClass: classes.defaultActiveBg,
          text: t('CHECKOUT_SUCCESS'),
        };
      case Status.checkoutFailed:
        return {
          icon: <Cancel className={colored ? classes.failedColor : classes.defaultColor} />,
          activeClass: classes.failedActiveBg,
          text: t('CHECKOUT_FAILED'),
        };
      case Status.created:
        return {
          icon: <CheckCircle className={colored ? classes.successColor : classes.defaultColor} />,
          activeClass: classes.defaultActiveBg,
          text: t('CREATED'),
        };
      case Status.routeStarted:
        return {
          icon: <CheckCircle className={colored ? classes.successColor : classes.defaultColor} />,
          activeClass: classes.defaultActiveBg,
          text: t('ROUTE_STARTED'),
        };
      case Status.onItsWay:
        return {
          icon: <CheckCircle className={colored ? classes.successColor : classes.defaultColor} />,
          activeClass: classes.defaultActiveBg,
          text: t('ON_ITS_WAY'),
        };
      case Status.updateDeliveryTrack:
        return {
          icon: <CheckCircle className={colored ? classes.successColor : classes.defaultColor} />,
          activeClass: classes.defaultActiveBg,
          text: t('UPDATE_DELIVERY_TRACK'),
        };
      default:
        return {
          icon: <Cancel className={colored ? classes.failedColor : classes.defaultColor} />,
          activeClass: classes.failedActiveBg,
          text: t('ERROR'),
        };
    }
  };

  const sortDates = (): ReactNodeArray => {
    return history
      .sort((a, b) => b.date.seconds - a.date.seconds)
      .map((history, index) => {
        const newDate = moment(new Date(history.date.seconds * 1000)).format(
          'LLLL',
        );
        let { icon, text, activeClass } = setStatus(history.status, index === 0);
        return history === undefined ? (
          <p>{t('Error')}</p>
        ) : (
          <Step key={index} active={true} className={index === 0 ? activeClass : ''}>
            <StepLabel
              className={classes.stepLabel}
              icon={icon}>
              <Typography
                className={classes.stepContent}
                style={{ fontSize: 14, color: '#666666' }}>
                {' '}
                {newDate}
              </Typography>
            </StepLabel>
            <StepContent>
              <Typography
                className={classes.stepContent}
                style={{ fontSize: 16 }}>
                {text}
              </Typography>
            </StepContent>
          </Step>
        );
      });
  };

  let displayElements = () => {
    let stepsToRender: ReactNodeArray = sortDates();
    if (displayAll) {
      return stepsToRender;
    }
    let stepToRender: ReactNode;
    if (stepsToRender && stepsToRender.length > 0) {
      if (isMobileDevice) {
        stepToRender = stepsToRender[0];
        return stepToRender;
      }
    }
    return stepsToRender;
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Card className={displayAll ? classes.cardWithoutOverflow : classes.card}>
        <CardHeader title={t('Status')} />
        <CardContent
          className={
            displayAll ? classes.cardWithoutOverflow : classes.cardContent
          }>
          {visits ? (
            <Stepper orientation={'vertical'} className={classes.stepper}>
              {displayElements()}
            </Stepper>
          ) : (
            t('Loading')
          )}
        </CardContent>
        {isMobileDevice && history.length > 1 ? (
          <CardActions>
            <Button
              className={classes.expandButton}
              onClick={onMobileDisplayStatus}>
              {displayAll ? t('ShowLess') : t('ShowMore')}
              {displayAll ? <ExpandLess /> : <ExpandMore />}
            </Button>
          </CardActions>
        ) : (
          <div />
        )}
      </Card>
    </MuiThemeProvider>
  );
}
