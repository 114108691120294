import { Coords } from 'google-map-react';

/// <reference types="@types/googlemaps" />

export function fitBounds(visit: Coords, driver: Coords) {
  // @ts-ignore
  const google = window.google;

  const bounds = new google.maps.LatLngBounds();
  bounds.extend(new google.maps.LatLng(visit));
  bounds.extend(new google.maps.LatLng(driver));
  // @ts-ignore
  // tslint:disable-next-line: no-unsafe-any
  window.map.fitBounds(bounds);
}
