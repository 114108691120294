import React, { useState, useEffect } from 'react';

import firebase from 'firebase/app';

import { MuiThemeProvider } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import logo from '../../assets/images/LiveTracking3.svg';

import { useStyles, someTheme } from './styles';
import { auth } from '../../algorithms/firebase.utils';
import { useTranslation } from 'react-i18next';

interface SearchPageProps {
  accountId?: number;
  history: any;
}

export function LandingPage({ accountId, history }: SearchPageProps) {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [renderErrorMessage, setRenderErrorMessage] = useState(false);
  const [trackingId, setTrackingId] = useState('');

  useEffect(() => {
    if (trackingId !== '') {
      history.push(`/${trackingId}`);
    }
  }, [trackingId]);

  const classes = useStyles();

  const searchByReference = () => {
    auth().then(v => {
      const collection = firebase.firestore().collection('DeliveryTrack');
      collection
        .where('accountId', '==', Number(accountId))
        .where('reference', '==', searchValue)
        .get()
        .then(qs => {
          let visits: any = [];

          qs.forEach(v => visits.push(v.data()));

          if (qs.empty) {
            setRenderErrorMessage(true);
            return;
          }
          let max = visits[0];

          visits.forEach((x: any) => {
            if (
              x.currentStatus.date.toDate() > max.currentStatus.date.toDate()
            ) {
              max = x;
            }
          });

          setTrackingId(max.trackingId);
        });
    });
  };

  const searchByTrackingId = () => {
    auth().then(v => {
      const collection = firebase.firestore().collection('DeliveryTrack');
      collection
        .where('accountId', '==', Number(accountId))
        .where('trackingId', '==', searchValue)
        .get()
        .then(qs => {
          qs.forEach(v => setTrackingId(v.data().trackingId));
          if (qs.empty) {
            setRenderErrorMessage(true);
            return;
          }
        });
    });
  };

  const search = () => {
    if (!accountId) {
      return;
    }

    if (searchValue.startsWith('SR')) {
      return searchByTrackingId();
    }
    return searchByReference();
  };

  const incorrectCode = (
    <div className={classes.incorrectTextWide}>
      <div className={classes.upperText}>{t('INCORRECT_CODE')}</div>
      <div className={classes.lowerText}>{t('TRY_AGAIN')}</div>
    </div>
  );

  const keyPressed = (e: any) => {
    if (e.key === 'Enter') {
      search();
    }
  };

  return (
    <MuiThemeProvider theme={someTheme}>
      <div className={classes.mainContainer}>
        <div className={classes.topContainer}>
          <img alt="logo" src={logo} className={classes.logo} />
          {renderErrorMessage ? incorrectCode : <React.Fragment />}
        </div>
        <Paper className={classes.inputContainer}>
          <InputBase
            className={classes.input}
            placeholder={t('SEARCH')}
            inputProps={{ 'aria-label': 'search your tracking code' }}
            name={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            onKeyPress={keyPressed}
          />
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="search"
            onClick={search}>
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
    </MuiThemeProvider>
  );
}
